<template>
  <div>
    <b-card
        text-variant="center"
        class="card card-congratulations"
    >
      <b-card-text class="m-auto w-75">
        {{ $t('You have') }} <strong v-if="currentItem.credit_amount">{{ currentItem.credit_amount }}</strong> <strong
          v-else
      > {{ $t('no') }}</strong> {{ $t('Credits available!') }}
      </b-card-text>
      <b-button
          v-if="currentItem.credit_amount"
          :to="{ name: 'credits-payout' }"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="p-10-px mt-2 payout"
          type="submit"
          size="sm"
      > {{ $t('Pay Out Credits') }}
      </b-button>
    </b-card>

    <b-card-actions
        ref="refreshCard"
        action-refresh
        disable-loading-on-refresh
        @refresh="getItems"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage"/>

      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refBookingtimesListTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter.input"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')">
          <template #head()="data">
            {{ $t(data.label) }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                type="submit"
                size="sm"
                @click="acceptRequest(data.item.id)"
            >
              {{ $t('Accept') }}
            </b-button>
            <b-button
                v-on:click="showModal(data.item.id)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                type="submit"
                size="sm"
            >
              {{ $t('Decline') }}
            </b-button>


          </template>
        </b-table>
      </b-overlay>

      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows"
      />
    </b-card-actions>
  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
import BCardActions from "@core/components/b-card-actions/BCardActions";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BAlert,
    BCardText,
    BImg,
    vSelect,
    TableHeader,
    TableFooter,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      tableColumns: [

        {
          key: 'type',
          sortable: true,
          label: 'Type',
          formatter: (value) => {
            return this.$i18n.t(value)
          }
        },
        {
          key: 'date',
          sortable: true,
          label: 'Date',
        },
        {
          key: 'Title',
          sortable: true,
          label: 'Title',
          formatter: (value) => {
            return moment(String(value))
                .format('DD.MM.YYYY HH:mm')
          }
        },

        // {key: 'actions', label: this.$i18n.t('Actions'), class: 'table-action-buttons'}
      ],
      items: [],
      currentItem: {}
    }
  },
  computed: {},
  mounted() {
    this.getItems()
    this.helperGetItem(`/info/credits`)
    window.addEventListener('userData', (event) => {
      this.userData = JSON.parse(event.detail.storage)
    })
  },

  methods: {
    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/experts/creditmanagement`)
    },
    deleteItem(id) {
      this.helperDeleteItem(
          `/employees/${id}`,
      )
    },

    formatName(value, key, item) {
      if (item.firstname == null && item.name == null) return item.email

      if (!item.firstname) return item.name

      return item.firstname + ' ' + item.name

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.p-10-px {
  padding: 10px;
}

.card-congratulations {
  background: #ad8a60;
}

.card {
  color: aliceblue;
}

.award {
  color: white;
}

.circle {
  background-color: #026666;
}

.payout {
  background-color: #026666 !important;
}
</style>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
